.wrapper-container{
    width: 95%;
    margin:0 auto;
    margin-top: 5%;
}

.footer-bgd-color{
    background-color: #fee9e9;
}

.social-img{
    width: 30px;
    height: 30px;
    margin: 7px;
    margin-top: 20px;
}

.social-sm-flex{
    display: flex;
}
ul li{
    list-style:none;
}